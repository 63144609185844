import { useState } from "react";
import Image from "../../../Assets/data_puzzle_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection9 = () => {
  const [showFullText, setShowFullText] = useState(false);

  const text =
    "boxMind's prowess in handling both structured and unstructured data is unparalleled. Our advanced data processing capabilities mean that no data set is too complex or too varied for our platform. Whether it's neatly organized databases or a myriad of unstructured data sources like emails, social media feeds, and text documents, boxMind effortlessly navigates and extracts valuable insights. This capability is crucial for businesses today, as the wealth of information lies not just in traditional databases but also in less structured formats. Our sophisticated algorithms and models are adept at deciphering, correlating, and analyzing data from diverse sources, transforming them into actionable intelligence. With boxMind, you're not just analyzing data; you're unlocking its full potential, ensuring every piece of information, regardless of its format, contributes to informed decision-making and strategic planning in your enterprise. This comprehensive approach to data management positions boxMind as an essential tool for businesses looking to leverage the full spectrum of their data assets in the evolving digital landscape.";

  const truncatedText = showFullText ? text : `${text.slice(0, 338)}...`;

  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row lg:gap-[190px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={Image}
              alt="pic"
              className=" lg:w-[400px]"
              loading="lazy"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] leading-tight text-[32px] font-semibold max-lg:text-center font-aeion">
              Mastering Both Structured and Unstructured Data
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal leading-[28px]">
              {truncatedText}
              {text.length > 300 && (
                <button
                  className="text-blue-500 underline cursor-pointer"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Read Less" : "Read More"}
                </button>
              )}
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection9;
