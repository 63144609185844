import { Helmet } from "react-helmet";
import React from "react";
import LAU3 from "../../Assets/test.png";
import AOU from "../../Assets/AOU.jpg";
import Summit from "../../Assets/Summit.jpg";
import Dubai from "../../Assets/MazenDubai.jpg";
import Dubai1 from "../../Assets/Dubai1.jpg";
import Saudi from "../../Assets/Saudi.jpg";
import { IoLogoLinkedin } from "react-icons/io5";
import { BsFillEnvelopeOpenFill } from "react-icons/bs";

interface Event {
  title: string;
  description: string;
  photos: string[];
  date: string;
  link: string;
}

const eventData: Event[] = [
  {
    title: `A Heartfelt Thanks to Badr Burshaid, Dr. Saadi Adra, and Lee R. Lambert for an Unforgettable Riyadh Global Project Management Forum`,
    description: `
🏆 Honored by the Trophy and Heartfelt Thanks to Three Remarkable Individuals 🏆

I want to extend my heartfelt thanks to three remarkable individuals who made the Riyadh Global Project Management Forum an unforgettable experience:
    
🔹First, the visionary leader Badr Burshaid بدر بورشيد 
Chapter President of PMI Kingdom of Saudi Arabia 🇸🇦 | PMOGA Fellow | World PMO Leader | Strategy Implementation Winner
Badr Burshaid بدر بورشيد vision exceeds the limits of the sky. As a man leading the way in project management, he understands the transformative power of GenAI. As the President of PMI in the Kingdom of Saudi Arabia, his modesty and attention to detail are astounding. You can't fully appreciate his impact until you meet him in person. His wisdom and kindness shine through from the very first conversation.
    
🔹Dr. Saadi Adra Winner of the Global PMO Influencer 2022 Award
he is the engine of PMI and the GPFM even, operating on what seems like a 25/24 schedule. His mastery in project management is evident, and you wonder when he finds time to sleep. His leadership and personality draw people to seek his guidance, making him an invaluable asset to the community.
    
🔹Lee R. Lambert the founder of PMI
Lee R. Lambert needs no introduction. Traveling thousands of miles to witness his creation flourish worldwide, he is a living legend. Everyone, including me, rushed to take photos with him. His smile reflects the pride in seeing PMI’s global impact, while his eyes envision the future that many of us can't yet see.
    
🔹As for me, I am honored to advocate for the true value of GenAI. I am thankful to find like-minded individuals who understand GenAI’s immense potential in project management. I expected to meet VIP managers in suits but found the most modest, intelligent, and amazing people instead. The friendships I’ve made here are the greatest prize, far surpassing any trophy.
    
🔹A special mention to all my fellow speakers who shined brightly at the event:
Your insights and expertise added immense value to our discussions. The dedication and passion you brought to the table were truly inspiring. Each of you contributed significantly to making this event a success. Your presentations highlighted the power of collaboration and the shared vision of integrating AI into project management. It was a privilege to share the stage with such talented professionals.`,
    photos: [Saudi],
    date: "6/2024",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7203705780334649346/",
  },
  {
    title: `A Grateful Journey at the AI in Dubai Conference with H.H. Sheikh Rashid bin Ahmed Al Maktoum`,
    description: `
Honored to be Recognized at the AI in Dubai Conference by H.H. Sheikh Rashid bin Ahmed Al Maktoum

As CTO of boxMind.ai, I am deeply honored to have received a trophy from H.H. Sheikh Rashid bin Ahmed Al Maktoum for my participation in the AI in Dubai Conference. This event has truly set the stage for Dubai to be the epicenter of AI innovation.
    
Special appreciation goes to Hikmat Beaini, DBA, LLM CEO of Future 10X Hikmat Beaini, DBA, LLM for orchestrating such a forward-thinking gathering. The dedication to amplify local AI enterprises is both commendable and crucial for our rapidly evolving industry.
    
I extend my gratitude to my fellow panelists, the insightful Carine Bouery , Dr. Nikolina Ljepava, PhD, FCIM, CDMP , and Asem Galal , for their thought-provoking discussions on the role of Generative AI in “Enhancing Customer and Consumer Services through Artificial Intelligence Advancements."
    
As we continue to push the boundaries of AI, let us celebrate the milestones achieved and the collaborative efforts that drive us towards a future woven with innovative technology.
    
As Dubai ascends to the pinnacle of AI innovation, becoming a Mecca for technologists worldwide, I am thrilled to be part of this transformative journey. The AI in Dubai Conference is a testament to the vision that's turning the city into a beacon of AI advancement.`,
    photos: [Dubai1],
    date: "1/2024",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7150968063880822784/",
  },
  {
    title: `Speaking at the AI Dubai Conference Palazzo Versace Dubai`,
    description: `
I'm honored to announce that I'll be speaking at the AI Dubai Conference Palazzo Versace Dubai, one of the largest AI events in the region, on January 8-9, 2024.
This event is a fantastic opportunity to connect with AI leaders and enthusiasts. 
Looking forward to seeing you there and discussing the future of AI together!`,
    photos: [Dubai],
    date: "1/2024",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7141485509180125184/",
  },
  {
    title: ` Incredible time at the Databricks AI and Data Summit in San Francisco!`,
    description: `
🌟Incredible time at the Databricks AI and Data Summit in San Francisco! 🚀✨

Just attended the gathering of over 12,000 professionals, all passionate about AI. The event was mind-blowing, with top AI minds sharing their insights. From cutting-edge algorithms to AI's impact on industries, it was an eye-opening experience! 💡💪
    
The exhibition hall showcased amazing AI tools, from natural language processing to computer vision. AI's potential to revolutionize healthcare, finance, and more is limitless! 🌍💻
    
But let's remember to develop AI responsibly, addressing ethical concerns. As we witness the evolution of mankind through AI, let's ensure it aligns with our values. Excited to contribute to this transformative era! 🌟🌏`,
    photos: [Summit],
    date: "7/2023",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7079926478762565632/",
  },
  {
    title: `Speaker at Google Group at Lebanese American University (LAU): Inspiring the Next Generation on the Future of Generative AI at Google Developer Groups`,
    description: `
Inspiring the Next Generation on the Future of Generative AI at Google Developer Groups - #devfest LAU

I had the privilege of delivering a lecture on the Future and Importance of Generative AI today, hosted by the Lebanese Google Dev Group at the Lebanese American University (LAU). 
    
🌟 Engaged Audience
I was delighted to see an auditorium filled with computer science students who are genuinely excited and curious about diving into the world of Generative AI.
    
📸 Event Highlights
Attached are some snapshots from today's engaging session. These moments capture the spirit and enthusiasm that's driving the next wave of AI innovation.
A big thank you to the Lebanese Google Dev Group and LAU for the opportunity to share my insights and connect with tomorrow's tech leaders. It's always an inspiration to see how many young minds are drawn to the transformative power of AI.`,
    photos: [LAU3],
    date: "10/2023",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7116534798285729792/",
  },
  {
    title: `Speaker at Arab Open University: The Future of Education Lies in Generative AI, and boxMind.ai is Leading the Charge!`,
    description: `
🌟 The Future of Education Lies in Generative AI, and boxMind.ai is Leading the Charge! 🌟

It's been an incredible journey witnessing the growth and impact of AI on multiple sectors, but the potential in education is unprecedented. We at boxMind.ai are deeply committed to this vision.
    
🏆 A Trophy for Team boxMind.ai 🏆
I was recently honored to receive an appreciation trophy at a workshop co-hosted by Arab Open University and Dr. Eng. Ahmed B. Mikati with the supervision of Dr. Yara Abdallah, Director of Arab Open University. I consider this award as not just for me, but for the entire team at boxMind.ai. Our engineers, data scientists, and visionaries are working around the clock to democratize AI and make it accessible and beneficial in educational settings.
    
🚀 Solving Edge Cases: Where Real Innovation Happens 🚀
The real test of any technology lies in how well it addresses edge cases. Generative AI has its challenges, but we are committed to refining our algorithms to solve these problems. Our team is focused on making AI not just functional but ethical, fair, and truly revolutionary.
    
💡 What's Next? 💡
Strategic Partnerships: Collaborations with educational institutes to refine and implement our Generative AI solutions.
Ethical Oversight: Establishing clear policies that ensure responsible AI usage in education.
Continuous Innovation: Pushing the envelope of what's possible with Generative AI in education.
    
🙏 A Thank You Note 🙏
👉 I want to thank Dr. Yara Abdallah, Director of Arab Open University, for the recognition and support. 
👉 Dr. Eng. Ahmed B. Mikati for his support and his role in Education and AI in Lebanon and the region.
👉 Dr. Mohamad Malli the CEO of boxMind.ai for his unlimited support and future vision to democratize AI in the region.
👉 boxMind.ai Dev and AI Team - The best GenAI Team ever
    
Together, we can make a difference that truly counts.`,
    photos: [AOU],
    date: "9/2023",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7107603810017030145/",
  },
];

const Events: React.FC = () => {
  return (
    <div className=" bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%] px-5">
      <Helmet>
        <title>boxMind Events</title>
        <meta
          name="description"
          content="Discover how boxMind is influencing the future of AI through our participation in global events. From Dubai to San Francisco, join us in shaping AI innovation."
        />
      </Helmet>
      <div className=" flex flex-col lg:flex-row justify-center items-center gap-3 lg:gap-[100px] py-4 ">
        <a
          href="https://lnkd.in/dB_DxP6R"
          target="blank"
          className=" flex flex-col lg:flex-row items-center gap-2"
        >
          <p className=" hover:text-gray-500 duration-300">
            🤖 Follow boxMind.ai page
          </p>
          <span className=" text-blue-700 hover:text-blue-900 duration-300">
            <IoLogoLinkedin size={25} />
          </span>
        </a>
        <a
          href="https://bit.ly/3sdLE67"
          target="blank"
          className=" flex flex-col lg:flex-row items-center gap-2"
        >
          <p className=" hover:text-gray-500 duration-300">
            🤖 Join our AI newsletter
          </p>
          <span className=" text-blue-700 hover:text-blue-900 duration-300">
            <BsFillEnvelopeOpenFill size={25} />
          </span>
        </a>
      </div>
      <div className=" flex flex-col gap-4">
        {eventData.map((event, index) => (
          <div
            key={index}
            className=" text-justify lg:w-[850px] mx-auto my-8 p-6 bg-white hover:bg-gray-300 duration-300 shadow-lg rounded-lg"
          >
            <a href={event.link} target="_blanck">
              <div className=" flex flex-col items-center justify-center py-2">
                <h2 className=" text-[20px] lg:text-[25px] text-center font-bold pb-2 font-aeion">
                  {event.title}
                </h2>
                <img
                  src={event.photos[0]}
                  alt="img"
                  loading="lazy"
                  className=" w-full lg:w-[1200px] rounded-md"
                />
                <p className="text-gray-600 max-w-[1200px] text-justify mb-4 whitespace-break-spaces">
                  {event.description}
                </p>
              </div>
              <div className="flex justify-end">
                <p className=" text-gray-500">{event.date}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
