import MrMazen from "../../../Assets/main_wide.jpg";
import DrMalli from "../../../Assets/DrMaill_fixed200x200.png";
import Linkedin from "../../../Assets/linkedin.png";

const Cards = () => {
  const data = [
    {
      id: 1,
      image: DrMalli,
      name: "Dr. Eng. Mohamad Malli",
      title: "CEO and co-founder",
      linkedin: "https://www.linkedin.com/in/mohamad-malli/",
      description: `
Dr. Mohamad Malli, CEO and co-founder of boxMind.ai, brings over two decades of expertise in AI and technology. His educational journey in computer science began with an Engineering Diploma from the Lebanese University in 2002, followed by Master's and Ph.D. degrees from Côte d'Azur University in 2006.
During his tenure at the French Research Institute INRIA and as an assistant professor at Côte d'Azur University, Dr. Malli further developed his expertise in computer science and networking, key in addressing business challenges and fostering growth through innovative solutions.
Holding certifications in strategic management, leadership, and change management, Dr. Malli adeptly applies these skills at boxMind.ai. His scientific contributions, including numerous research papers in international journals and conferences, underscore his deep understanding of AI.
In addition to his primary role at boxMind.ai, Dr. Malli dedicates time to advancing AI education as a senior lecturer and as a member of the Ministry of Higher Education's Curriculum Specialists Committee. His leadership at boxMind.ai is focused on developing AI solutions that enhance business efficiency and societal well-being, reflecting his commitment to innovation in the AI sector.
      `,
    },
    {
      id: 2,
      image: MrMazen,
      name: "Mr. Mazen Lahham",
      title: "CTO and co-founder",
      linkedin: "https://www.linkedin.com/in/mazenlhm/",
      description: `
Mazen Lahham embarked on his career in computer science in 1986 with an introductory course on punch card programming.
Over the course of more than three decades, he has amassed extensive expertise in a wide array of technology domains, including but not limited to  Artificial Intelligence, Robotics, and Security.
In 2016, Mazen was honored as the Best Arab Software Developer at the Smart Government ceremonial in Kuwait, under the patronage of His Excellency Sheikh Mohammed Al-Abdullah Al-Sabah and attended by heads of diplomatic bodies of the UN.
His leadership capabilities extend to significant institutional projects; he has managed mega software endeavors in partnership with the World Bank and UN. Furthermore, Mazen was selected to oversee critical governmental projects, including the Dubai Municipality Systems.
Recognizing the transformative shifts in the field of computer science, boxMind.ai represents the culmination of Mazen's journey - a journey marked by relentless pursuit of technological mastery, a deep understanding of the transformative nature of Gen AI and computer science, and a commitment to leading the next generation of technological advancements.`,
    },
  ];

  return (
    <div className=" flex flex-col lg:flex-row items-center justify-center gap-10 p-4">
      {data.map((item, index) => (
        <div
          className="  p-4 flex flex-col gap-4 h-[800px] rounded-lg duration-300 bg-[#EDEAF8]"
          key={item.id}
        >
          <div className=" flex flex-col items-center justify-center">
            <img
              loading="lazy"
              src={item.image}
              alt={item.name}
              className=" w-[200px] h-[200px] rounded-full object-cover object-top"
            />
            <h2 className=" text-[30px] text-center font-aeion">{item.name}</h2>
            <h3 className="font-aeion">{item.title}</h3>
            <a
              target="_blank"
              rel="noreferrer"
              className="h-[30px] w-[30px] mt-2 -mb-2"
              href={item.linkedin}
            >
              <img src={Linkedin} alt="Linked in" />
            </a>
          </div>
          <p className=" text-justify max-w-[500px] max-h-[700px] overflow-y-scroll whitespace-break-spaces custom-scrollbar text-gray-700">
            {item.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Cards;
