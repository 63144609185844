import Image from "../../../Assets/data_privacy_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection5 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col lg:flex-row lg:gap-[140px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-5"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] leading-tight max-lg:text-center text-[32px] font-semibold font-aeion">
              Your Data, Your Privacy: Our Priority
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              Data privacy is at the core of boxMind's design. We ensure that
              all operations occur onsite within a secure, closed system. This
              closed-loop processing means your sensitive enterprise data never
              leaves your premises, guaranteeing complete confidentiality. With
              no third-party data transfer, we provide an environment where
              businesses can freely analyze their data, assured in the knowledge
              that their information remains private and protected.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img src={Image} alt="pic" loading="lazy" className=" h-[389px]" />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection5;
