import { useState } from "react";
import Image from "../../../Assets/main_poining_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection10 = () => {
  const [showFullText, setShowFullText] = useState(false);

  const text =
    "Imagine harnessing the power of an AI mind within your enterprise, akin to having your own ChatGPT on-site. This is the reality we bring with boxMind. Our team of AI researchers has meticulously evaluated over 50 open-source LLM models, including versions starting from ChatGPT-3, to deliver an unparalleled balance of accuracy, cost, performance, and data privacy. This AI mind is not just another tool; it's like adding a new brain to your enterprise, offering perspectives and insights previously unattainable. With boxMind, your structured and unstructured data is constantly fed into this AI engine, ensuring it operates with the freshest data around the clock. This ongoing data nourishment allows your AI mind to evolve and adapt continuously, providing your business with a dynamic, intelligent asset that revolutionizes how you interpret and leverage your data. By integrating this AI powerhouse into your enterprise, you'll witness a transformation in how you understand and utilize your data, marking a new epoch in your organizational intelligence and decision-making processes.";

  const truncatedText = showFullText ? text : `${text.slice(0, 512)}...`;

  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col lg:flex-row lg:gap-[30px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-5"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] leading-tight text-[32px] font-semibold max-lg:text-center font-aeion">
              Empower Your Enterprise with an On-Site AI Mind
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal leading-[28px]">
              {truncatedText}
              {text.length > 300 && (
                <button
                  className="text-blue-500 underline cursor-pointer"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Read Less" : "Read More"}
                </button>
              )}
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img src={Image} alt="pic" className=" h-[389px]" loading="lazy" />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection10;
