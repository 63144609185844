import { Helmet } from "react-helmet";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import Image from "../../Assets/FAQ.png";

const FAQ = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const faq = [
    {
      title: "What is the core philosophy behind boxMind?",
      content:
        "boxMind is designed to revolutionize data analytics within enterprises, enabling businesses to explore new dimensions of data interpretation using AI in a secure, private environment.",
    },
    {
      title: "Can boxMind handle both structured and unstructured data?",
      content:
        "Absolutely. boxMind is adept at processing and analyzing both structured and unstructured data, offering comprehensive insights from a variety of data sources.",
    },
    {
      title: "How does boxMind ensure data privacy?",
      content:
        "boxMind operates on a closed-loop processing system, with all operations occurring onsite within a secure, closed system, ensuring the utmost confidentiality and no third-party data transfer.",
    },
    {
      title: "What does boxMind's Multiple-Models AI System offer?",
      content:
        "Our Multiple-Models AI System allows users to choose the most suitable Large Language Model for various tasks, providing flexibility and customization to meet diverse data processing needs.",
    },
    {
      title: "How does boxMind manage user access and data security?",
      content:
        "boxMind features a Role and Permission Management system, allowing customizable roles to ensure users access only the necessary data, thereby enhancing data security.",
    },
    {
      title: "What capabilities does boxMind offer for handling big data?",
      content:
        "boxMind is equipped with hyperscale capability, efficiently managing and processing large volumes of data, as demonstrated by processing the entire content of Wikipedia in just 20 milliseconds.",
    },
    {
      title: "Can I use the latest version of ChatGPT with boxMind?",
      content:
        "Yes, no problem. boxMind is dynamic and can integrate with any type of Large Language Model, including the latest versions of ChatGPT.",
    },
    {
      title: "Does boxMind implement a RAG AI system?",
      content:
        "Not only RAG, but we also use a wide range of General AI technologies including RAG and fine-tuning, to offer versatile and advanced AI solutions.",
    },
    {
      title: "How does boxMind deliver AI solutions economically?",
      content:
        "boxMind balances quality and cost by thoroughly testing open-source models, ensuring high-end AI capabilities at an affordable price.",
    },
    {
      title: "What makes boxMind unique in terms of performance?",
      content:
        "Equipped with advanced GPU servers, boxMind provides consistent high-speed performance and can handle significant data demands efficiently.",
    },
    {
      title: "How does boxMind contribute to enterprise-level data analysis?",
      content:
        "boxMind is a transformative business tool, integrating various AI tasks to streamline information flow within an organization, much like ERP systems revolutionized business process management.",
    },
    {
      title: "What type of data can boxMind integrate with?",
      content: `boxMind is designed to be compatible with a wide range of data types, including emails, internet content, and various unstructured data formats.
       Your requirements, data size, required GPU, and other technical aspects. We recommend contacting us for a free assessment to determine the most cost-effective solution for your needs.
        `,
    },
    {
      title: "How about the performance of your solution?",
      content:
        "Our platform has been rigorously tested in big data scenarios, including an offline version of Wikipedia. It has demonstrated exceptional performance, with query response times of only 20 milliseconds for accessing 18 million offline Wikipedia articles.",
    },
  ];

  const [selectedFaq, setSelectedFaq] = useState<string[]>([]);

  const handleCheckBoxChange = (id: string) => {
    setSelectedFaq((prevSelectedFaq) => {
      if (prevSelectedFaq.includes(id)) {
        return prevSelectedFaq.filter((faq_id) => faq_id !== id);
      } else {
        return [...prevSelectedFaq, id];
      }
    });
  };

  const handleSelect = (num: any) => {
    var div = document.getElementById(`expand-box-${num}`);
    if (div === null) {
    } else {
      if (div.className.includes("overflow-hidden")) {
        div.className =
          "transition-[max-height_opacity] duration-300 text-large-body max-md:text-medium-body max-sm:text-small-body max-w-[696px] opacity-60 max-h-[20rem]";
      } else {
        div.className =
          "transition-[max-height_opacity] duration-300 text-large-body max-md:text-medium-body max-sm:text-small-body max-w-[696px] opacity-60 overflow-hidden opacity-0 max-h-0";
      }
    }
  };

  return (
    <section className="bg-[#F8FBFD] overflow-hidden text-black lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind FAQ</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about boxMind, covering data processing, security, AI models, and more. Learn how our AI solutions can transform your enterprise."
        />
      </Helmet>
      <div className="container flex flex-col items-center justify-center mx-auto">
        <div className="flex justify-center text-center">
          <img
            src={Image}
            loading="lazy"
            alt="pic"
            className=" lg:w-[830px] w-[500px] h-full object-cover"
          />
        </div>
        <div className="flex flex-col px-4 w-[850px] max-xl:min-w-full ">
          {faq.map((item, i) => {
            return (
              <div
                id=""
                key={i}
                className="flex flex-col pt-[15px] max-lg:items-center transition-[height] duration-300"
              >
                <div
                  className={`flex flex-col gap-[1rem] p-2 cursor-pointer rounded-lg ${
                    selectedFaq.includes(i.toString()) ? " bg-gray-100" : null
                  } duration-300`}
                  onClick={() => {
                    handleCheckBoxChange(i.toString());
                    handleSelect(i);
                  }}
                >
                  <div className="flex w-full justify-center lg:justify-between items-center relative select-none">
                    <p className=" md:w-full max-sm:max-w-[278px] font-aeion">
                      {item.title}
                    </p>
                    <button aria-label="Expand FAQ answer">
                      <div
                        className={
                          "transition-[transform] duration-300 " +
                          (selectedFaq.includes(i.toString())
                            ? "rotate-45"
                            : " -rotate-90")
                        }
                      >
                        <AiOutlinePlus />
                      </div>
                    </button>
                  </div>
                  <div className="max-lg:flex max-lg:justify-center max-lg:items-center max-lg:max-w-[378px]">
                    <p
                      id={`expand-box-${i}`}
                      className={
                        "transition-[max-height_opacity] duration-300 max-w-[696px] opacity-60 " +
                        (selectedFaq.includes(i.toString())
                          ? "max-h-[20rem]"
                          : "overflow-hidden opacity-0 max-h-0")
                      }
                    >
                      {item.content}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
          <div
            onClick={toggleContentVisibility}
            className="flex flex-col pt-[15px] max-lg:items-center transition-[height] duration-300"
          >
            <div
              className={`flex flex-col gap-[1rem] p-2 cursor-pointer rounded-lg ${
                isContentVisible ? "bg-gray-100" : ""
              }   duration-300`}
            >
              <div className="flex w-full justify-center lg:justify-between items-center relative select-none">
                <p className=" md:w-full max-sm:max-w-[278px] font-aeion">
                  How does your solution compare with ChatGPT?
                </p>
                <button aria-label="Expand FAQ answer">
                  <div
                    className={`transition-[transform] duration-300 ${
                      isContentVisible ? " rotate-45" : " -rotate-90"
                    }`}
                  >
                    <AiOutlinePlus />
                  </div>
                </button>
              </div>
              <div
                className={`max-lg:flex max-lg:justify-center max-lg:items-center max-lg:max-w-[378px] transition-[max-height_opacity] duration-300`}
              >
                <div
                  className={`transition-[max-height_opacity] duration-300 max-w-[696px] opacity-60 ${
                    isContentVisible
                      ? "max-h-[20rem]"
                      : "overflow-hidden opacity-0 max-h-0"
                  } `}
                >
                  boxMind differs from ChatGPT in several key aspects. Firstly,
                  our solution is tailored to work with your private data,
                  ensuring cost-effectiveness and eliminating the need to upload
                  sensitive data externally. Secondly, we have achieved results
                  that outperform ChatGPT 4 in specific enterprise applications.
                  For more insights into this comparison,
                  <a
                    className=" text-blue-700 hover:text-blue-800 duration-300"
                    target="blank"
                    href="https://www.linkedin.com/pulse/beyond-hype-gpts-reality-check-ai-development-boxmindai-mazen-lahham-1665f/?trackingId=WNij0sMZQWuyOF5zqaAOhQ%3D%3D"
                  >
                    check out our article here
                  </a>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
