import Image from "../../../Assets/boxMind_with_box_transparent.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection2 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row items-center lg:gap-[195px] gap-[50px]">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" max-lg:flex max-lg:justify-center"
          >
            <img src={Image} alt="pic" loading="lazy" className=" h-[389px]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] leading-tight text-[32px] font-semibold max-lg:text-center font-aeion">
              Redefining Data Analytics with boxMind
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              At the heart of boxMind is a pioneering philosophy: to transform
              how enterprises engage with data. It's about challenging the
              status quo, encouraging businesses to "think inside the box" with
              AI as their guide. Our platform is not just a tool; it's a mindset
              shift, enabling a more profound, more insightful interpretation of
              data within a secure and private environment. We believe in
              empowering businesses to unlock new dimensions in their data,
              fostering an ecosystem where innovation and privacy coexist
              seamlessly.
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection2;
