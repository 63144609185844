import Image from "../../../Assets/Man_holding_box_with_box.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection8 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col lg:flex-row lg:gap-[100px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-5"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] text-[32px] leading-tight font-semibold  max-lg:text-center font-aeion">
              Peak Performance with Advanced Technology
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              Performance is key in enterprise AI, and boxMind excels in this
              area. Equipped with advanced GPU servers, our platform delivers
              optimal performance consistently. Whether your data demands are
              large or small, boxMind ensures quick and reliable response times
              even under heavy loads. This consistent high-speed processing
              power means that enterprises can rely on boxMind for efficient,
              uninterrupted data analysis, making it a cornerstone for
              businesses that require timely and accurate insights.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={Image}
              alt="pic"
              className=" w-[500px] h-[389px]"
              loading="lazy"
            />
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection8;
