import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { countries, ICountry } from "countries-list";

const countryOptions = Object.keys(countries).map((countryCode) => ({
  value: countryCode,
  label: (countries as Record<string, ICountry>)[countryCode].name,
}));

const ContactUs = () => {
  const [formData, setFormData] = useState({
    subject: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    industry: "",
    jobTitle: "",
    companySize: "",
    hear: "",
    hearOther: "",
    message: "",
    country: "",
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "hear") {
      setShowOtherInput(value === "other");
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!recaptchaValue) {
      toast.error("Please complete the ReCAPTCHA verification.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }
    setLoading(true);
    const formBody = new URLSearchParams(formData).toString();

    try {
      const response = await fetch(
        "https://boxmind.ai/mailer/phpmailer/index.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      if (response.ok) {
        toast.success("Form submitted successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        setFormData({
          subject: "",
          fullName: "",
          email: "",
          phoneNumber: "",
          companyName: "",
          industry: "",
          jobTitle: "",
          companySize: "",
          hear: "",
          hearOther: "",
          message: "",
          country: "",
        });
      } else {
        toast.error("Failed to submit form", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("Failed to submit form", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } finally {
      setLoading(false);
      setFormData({
        subject: "",
        fullName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        industry: "",
        jobTitle: "",
        companySize: "",
        hear: "",
        hearOther: "",
        message: "",
        country: "",
      });
    }
  };

  return (
    <section className="bg-[#135991] p-4 flex flex-col justify-center items-center">
      <p className="text-center text-white lg:text-[64px] text-[30px] font-semibold font-aeion">
        Drop us a Line
      </p>
      <form
        className="flex flex-col gap-3 items-center mt-4 px-4 max-lg:w-full"
        onSubmit={handleSubmit}
      >
        <div className=" flex flex-col gap-2 w-full">
          <label htmlFor="subject" className=" text-gray-300">
            Subject*
          </label>
          <input
            type="text"
            name="subject"
            id="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            placeholder="Subject"
            className="border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none"
          />
        </div>
        <div className=" flex flex-col w-full lg:flex-row justify-between gap-3">
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="Full Name" className=" text-gray-300">
              Full Name*
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              placeholder="Full Name"
              className="border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none"
            />
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="Email" className=" text-gray-300">
              Email*
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
              className="border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none"
            />
          </div>
        </div>
        <div className=" flex flex-col w-full lg:flex-row justify-between gap-3">
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="Phone Number" className=" text-gray-300">
              Phone Number*
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              placeholder="Phone Number"
              className="border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none"
            />
          </div>
          <div className=" flex flex-col gap-2 w-full">
            <label htmlFor="Company Name" className=" text-gray-300">
              Company Name*
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              placeholder="Company Name"
              className="border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label htmlFor="Country" className="text-gray-300">
            Country*
          </label>
          <select
            name="country"
            id="contry"
            autoComplete="off"
            value={formData.country}
            onChange={handleChange}
            required
            className="border text-white rounded bg-[#135991] px-2 py-1 w-full border-[#719bbd] outline-none"
          >
            <option disabled value="">
              Select your country
            </option>
            {countryOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className=" flex flex-col gap-2 w-full">
          <label htmlFor="Industry" className=" text-gray-300">
            Industry*
          </label>
          <select
            name="industry"
            id="industry"
            value={formData.industry}
            onChange={handleChange}
            required
            className="border text-white rounded bg-[#135991] px-2 py-1 w-full border-[#719bbd] outline-none"
          >
            <option disabled value="">
              Select a job industry
            </option>
            <option value="IT">Information Technology</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Education">Education</option>
            <option value="Retail">Retail</option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Energy">Energy</option>
            <option value="Media">Media and Entertainment</option>
            <option value="Consulting">Consulting</option>
          </select>
        </div>
        <div className=" flex flex-col gap-2 w-full">
          <label htmlFor="Job Title" className=" text-gray-300">
            Job Title*
          </label>
          <select
            name="jobTitle"
            id="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            required
            className="border text-white rounded bg-[#135991] px-2 py-1 w-full border-[#719bbd] outline-none"
          >
            <option disabled value="">
              Select a job title
            </option>
            <option value="SoftwareEngineer">Software Engineer</option>
            <option value="DataAnalyst">Data Analyst</option>
            <option value="ProductManager">Product Manager</option>
            <option value="MarketingCoordinator">Marketing Coordinator</option>
            <option value="FinancialAnalyst">Financial Analyst</option>
            <option value="HRManager">HR Manager</option>
            <option value="SalesRepresentative">Sales Representative</option>
            <option value="CustomerServiceRepresentative">
              Customer Service Representative
            </option>
            <option value="OperationsManager">Operations Manager</option>
            <option value="GraphicDesigner">Graphic Designer</option>
          </select>
        </div>
        <div className=" flex flex-col gap-2 w-full">
          <label htmlFor="Company Size" className=" text-gray-300">
            Company Size*
          </label>
          <select
            name="companySize"
            id="companySize"
            value={formData.companySize}
            onChange={handleChange}
            required
            className="border text-white rounded bg-[#135991] px-2 py-1 w-full border-[#719bbd] outline-none"
          >
            <option disabled value="">
              Select the company size
            </option>
            <option value="1-5">1-5</option>
            <option value="5-10">5-10</option>
            <option value="10-20">10-20</option>
            <option value="20-50">20-50</option>
            <option value="50-100">50-100</option>
            <option value="100-500">100-500</option>
            <option value="above 500">Above 500</option>
          </select>
        </div>
        <div className=" flex flex-col gap-2 w-full">
          <label
            htmlFor="How did you hear about us?"
            className=" text-gray-300"
          >
            How did you hear about us?*
          </label>
          <div className=" flex flex-col gap-3">
            <select
              name="hear"
              id="hear"
              value={formData.hear}
              onChange={handleChange}
              required
              className="border text-white rounded bg-[#135991] px-2 py-1 w-full border-[#719bbd] outline-none"
            >
              <option disabled value="">
                Select a platform
              </option>
              <option value="linkedln">Linkedln</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
              <option value="twitter">Twitter</option>
              <option value="youtube">Youtube</option>
              <option value="friend">Friend/Family</option>
              <option value="advertisement">Google</option>
              <option value="other">Other</option>
            </select>
            {showOtherInput && (
              <div>
                <label
                  htmlFor="Can you please specify?"
                  className=" text-gray-300"
                >
                  Can you please specify?
                </label>
                <input
                  type="text"
                  id="hearOther"
                  name="hearOther"
                  value={formData.hearOther}
                  onChange={handleChange}
                  placeholder="Specify other platform"
                  className=" bottom-0 border text-white rounded px-2 py-1 bg-transparent border-[#719bbd] outline-none w-full"
                />
              </div>
            )}
          </div>
        </div>
        <div className=" flex flex-col gap-2 w-full">
          <label htmlFor="Message" className=" text-gray-300">
            Message*
          </label>
          <textarea
            name="message"
            id="message"
            required
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className="border text-white rounded h-[100px] px-2 py-1 bg-transparent border-[#719bbd] outline-none w-full"
          />
        </div>
        <ReCAPTCHA
          sitekey="6Lf0ASwpAAAAAKw-ddwbvo9q7b5fVD33NrDSTAQI"
          onChange={handleRecaptchaChange}
        />
        <div className="pb-4">
          <button
            disabled={loading}
            className="text-white border border-[#719bbd] px-4 py-2 rounded-lg uppercase bg-transparent"
          >
            {loading ? "Sending..." : "Send your message"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
