import React from "react";
import boxMind from "../../Assets/boxMind_transparent.png";
import { Link, useLocation } from "react-router-dom";
import { GrYoutube } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <footer className=" bg-[#F8FBFD] flex flex-col justify-center items-center">
      <hr className=" mt-6 border-[2px] border-stone-300 w-full max-w-[1000px] rounded-lg" />
      <div className=" text-gray-400 text-[15px] lg:w-[1040px] mx-auto flex flex-col gap-4 lg:flex-row items-center justify-between px-4 py-8">
        <div className=" flex flex-col gap-2 items-center text-center">
          <h1 className=" text-[18px] text-[#1D6196] underline">Company</h1>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/use-cases" ? "text-[#135991] duration-300" : null
            }`}
            to="/use-cases"
          >
            Use Cases
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/faq" ? "text-[#135991] duration-300" : null
            }`}
            to="/faq"
          >
            FAQ
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/events" ? "text-[#135991] duration-300" : null
            }`}
            to="/events"
          >
            Events
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/articles" ? "text-[#135991] duration-300" : null
            }`}
            to="/articles"
          >
            Articles
          </Link>
          <a
            className=" hover:text-[#135991] duration-300"
            href="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            className=" hover:text-[#135991] duration-300"
            href="/mediakit"
            target="_blank"
          >
            Mediakit
          </a>
        </div>
        <div className=" flex flex-col gap-2 items-center text-center">
          <h1 className=" text-[18px] text-[#1D6196] underline">About Us</h1>
          {/* <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/ai-heroes" ? "text-[#135991] duration-300" : null
            }`}
            to="/ai-heroes"
          >
            AI Heroes
          </Link> */}
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/ai-ethics" ? "text-[#135991] duration-300" : null
            }`}
            to="/ai-ethics"
          >
            AI Ethics
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/co-founders" ? "text-[#135991] duration-300" : null
            }`}
            to="/co-founders"
          >
            Co Founders
          </Link>
          <Link
            className={` hover:text-[#135991] duration-300 ${
              pathname === "/philosophy" ? "text-[#135991] duration-300" : null
            }`}
            to="/philosophy"
          >
            Philosophy
          </Link>
        </div>
        <div className=" flex flex-col items-center lg:pb-8">
          <img
            src={boxMind}
            alt="boxMind"
            loading="lazy"
            width={166}
            height={130}
          />
          <p className="text-center text-[#1D6196] text-sm font-normal capitalize leading-snug">
            Think inside the box with AI
          </p>
        </div>
      </div>
      <div className={`flex flex-row items-center gap-4 text-gray-400 pb-4`}>
        <a
          href="https://youtube.com/@boxMindai"
          target="blank"
          className={` hover:text-[red] duration-300`}
        >
          <GrYoutube size={35} />
        </a>
        <a
          href="https://www.linkedin.com/company/boxmindai"
          target="blank"
          className={` hover:text-[#135991] duration-300`}
        >
          <FaLinkedinIn size={30} />
        </a>
      </div>
      <div className="text-sm flex flex-row gap-2 text-gray-500 sm:text-center dark:text-gray-400 pb-4">
        <p>© 2025 All Rights Reserved.</p>
        <a
          className=" text-gray-700 underline hover:text-gray-500 duration-300"
          href="/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </a>
        <a
          className=" text-gray-700 underline hover:text-gray-500 duration-300"
          href="/mediakit"
          target="_blank"
        >
          Mediakit
        </a>
      </div>
    </footer>
  );
};

export default Footer;
