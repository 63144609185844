import { Helmet } from "react-helmet";
import Image from "../../Assets/PrivacyPolicy.jpg";

const PrivacyPolicy = () => {
  return (
    <section className=" bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind Privacy-Policy</title>
        <meta
          name="description"
          content="Read boxMind's Privacy Policy to understand how we collect, use, and protect your information. Learn about your rights and our commitment to data privacy."
        />
      </Helmet>
      <div className="flex justify-center text-center">
        <img
          src={Image}
          alt="pic"
          loading="lazy"
          className=" lg:w-[850px] w-[500px] h-full object-cover"
        />
      </div>
      <div className="text-justify lg:w-[850px] mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
        <p className="text-gray-700 mb-6">
          Effective Date: January 1, 2023 This Privacy Policy describes how
          boxMind.ai (“we,” “us,” or “our”) collects, uses, and shares
          information in connection with your use of our website (boxMind.ai),
          services, and applications (collectively, the “Services”). This policy
          does not apply to information you may provide to us offline or through
          any means other than our Services. For example, this policy does not
          govern the practices of third parties who may also collect or receive
          data in connection with your use of the Services.
        </p>

        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2 font-aeion">
            Information We Collect:
          </h3>
          <p className="text-gray-700">
            We may collect and store information about you when you access or
            use our Services. This can include, but is not limited to: personal
            information like your name, email address, phone number, and any
            other information that you choose to provide; information about how
            you use our Services; information about how you connect to our
            Services, including the type of device you’re using and its
            software, unique device identifiers, browser types, language
            settings, IP addresses, and location information.
          </p>
        </div>

        <div className="mb-6">
          <div className="mb-4">
            <h4 className="text-xl font-bold mb-2 font-aeion">
              How We Use Your Information:
            </h4>
            <p className="text-gray-700">
              We may use the information we collect for a variety of purposes,
              including to provide, maintain, and improve our Services; to
              communicate with you, respond to your requests or comments, and
              send you updates and administrative messages; to understand your
              needs and interests, and personalize your experience with our
              Services; to conduct research and analysis related to our
              Services; to enforce our Terms of Service and other usage
              policies; and to comply with legal obligations, resolve disputes,
              and enforce our agreements.
            </p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Sharing Your Information:
            </h4>
            <p className="text-gray-700">
              We may share your information with third parties under certain
              circumstances. For instance, we may share your information with
              our service providers who perform services on our behalf; in
              response to a legal request, if we believe that sharing your
              information is necessary to comply with that request; in
              connection with, or during the negotiation of, any merger,
              acquisition, sale of assets, or any business transition.
            </p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">Your Choices:</h4>
            <p className="text-gray-700">
              You have certain rights and choices in connection with your
              information. You can update, correct, or delete your personal
              information; you can choose not to receive promotional
              communications from us; and you can usually adjust your browser’s
              settings to remove or reject cookies.
            </p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Changes to this Privacy Policy:
            </h4>
            <p className="text-gray-700">
              We may revise this Privacy Policy from time to time. The most
              current version will always be on our website (boxMind.ai). If a
              revision, in our sole discretion, is material, we will notify you.
              By continuing to access or use the Services after revisions become
              effective, you agree to be bound by the revised Privacy Policy.
            </p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">Contact Us:</h4>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, please
              contact us using the contact us form.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
