import Image from "../../../Assets/hacker_small.png";
import { AnimatePresence, motion } from "framer-motion";

const SubSection6 = () => {
  return (
    <section className=" flex justify-center items-center w-full py-4">
      <AnimatePresence>
        <div className=" flex flex-col-reverse lg:flex-row lg:gap-[40px] gap-[50px] items-center">
          <motion.div
            initial={{ opacity: 0, x: -9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" max-lg:flex max-lg:justify-center"
          >
            <img
              src={Image}
              alt="pic"
              className=" lg:h-[389px]"
              loading="lazy"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 9 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 9 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className=" flex flex-col max-lg:px-4"
          >
            <h1 className="lg:w-[612px] gradient-text lg:text-[40px] text-[32px] leading-tight font-semibold max-lg:text-center font-aeion">
              Selective Access for Enhanced Security
            </h1>
            <p className="text-justify lg:w-[592px] text-zinc-500 text-base font-normal  leading-[28px]">
              boxMind's Role and Permission Management system is a testament to
              our commitment to secure data analytics. We enable customizable
              roles within your organization, ensuring that users have access
              only to the data they need. This selective access enhances overall
              data security and maintains integrity across your enterprise.
              Furthermore, our robust data interaction security measures ensure
              that user interactions are strictly confined to their designated
              data sets, safeguarding against unauthorized access and data
              breaches.
            </p>
          </motion.div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default SubSection6;
