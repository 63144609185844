import { Helmet } from "react-helmet";
import Image from "../../Assets/NewBoxMind.png";
import PresentTurboMind from "../../Assets/PresentTurboMind.jpg";

const Philosophy = () => {
  return (
    <section className=" bg-[#F8FBFD] overflow-hidden lg:pt-[5%] pt-[25%]">
      <Helmet>
        <title>boxMind Philosophy</title>
        <meta
          name="description"
          content="Explore the core philosophy of boxMind, where advanced AI meets enterprise needs. Learn how our Large Language Models (LLMs) and AI solutions are redefining data analytics."
        />
      </Helmet>
      <div className="flex justify-center text-center">
        <img
          src={Image}
          loading="lazy"
          alt="pic"
          className=" lg:w-[850px] w-[500px] h-full object-cover"
        />
      </div>
      <div className="text-justify lg:w-[850px] mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold mb-4 font-aeion">
          boxMind Enterprise
        </h2>
        <p className="text-gray-700 mb-6">
          BoxMind Enterprise is a state-of-the-art AI solution specifically
          designed for enterprise-level data analysis. Utilizing the power of
          advanced Large Language Models (LLMs), this platform offers
          unparalleled insights into both structured and unstructured data,
          transcending the capabilities of conventional database queries.
        </p>

        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2 font-aeion">Core Philosophy</h3>
          <p className="text-gray-700">
            boxMind is centered on the concept of redefining data analytics
            within the enterprise. It's about enabling businesses to "think
            inside the box," using AI to unlock new dimensions of data
            interpretation in a secure, private environment exclusively for the
            data owners.
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-bold mb-2 font-aeion">
            Comprehensive Feature Overview
          </h3>

          <div className="mb-4">
            <h4 className="font-bold mb-2 text-gray-700">
              Multiple-Models AI System
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Flexibility: Users can select the most suitable LLM for various
                tasks, enhancing efficiency and accuracy.
              </li>
              <li>
                Customization: Adaptable to different data processing needs,
                whether it's writing emails or performing complex data
                retrievals.
              </li>
              <li>
                Section Introduction: This section emphasizes the versatile
                nature of boxMind in accommodating different AI models for
                diverse enterprise needs.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">Data Privacy</h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Closed-Loop Processing: All operations occur onsite, within a
                secure, closed system, ensuring data privacy.
              </li>
              <li>
                No Third-Party Data Transfer: Guarantees complete
                confidentiality of sensitive enterprise data.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Role and Permission Management
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Selective Access: Customizable roles ensure users access only
                what they need, enhancing data security.
              </li>
              <li>
                Data Interaction Security: Ensures that user interactions are
                confined to their designated data sets.
              </li>
              <li>
                Section Introduction: Highlights the system's ability to manage
                data access securely, crucial for enterprise data integrity.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Big Data Handling
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Hyperscale Capability: Designed to handle vast volumes of data
                efficiently.
              </li>
              <li>
                Demonstrated Efficiency: In a notable test, the system processed
                the entire content of Wikipedia, including over 18 million
                sub-articles, in just 20 milliseconds.
              </li>
              <li>
                Section Introduction: Emphasizes boxMind's capability to manage
                and process large-scale data sets swiftly and effectively.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Cost-Effectiveness
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Balanced Quality and Cost: Thorough testing of open-source
                models to deliver quality at a lower cost.
              </li>
              <li>
                Economic AI Solutions: Offers high-end AI capabilities at an
                affordable price.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">Performance</h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Advanced GPU Servers: Equipped with high-end servers for optimal
                performance.
              </li>
              <li>
                Consistent High Speed: Ensures quick and reliable response times
                even under heavy loads.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Advanced Data Processing
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Comprehensive Data Handling: Capable of managing both structured
                and unstructured data with advanced techniques like RAG,
                fine-tuning, and AI agents.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Compatibility with Various Data Types
            </h4>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Wide-Ranging Data Integration: Capable of processing data from
                diverse sources, including emails and internet content.
              </li>
              <li>
                Adaptable Framework: Designed to be compatible with a variety of
                unstructured data formats.
              </li>
            </ul>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2 font-aeion">
              Unified AI Platform
            </h4>
            <div className="flex justify-center text-center">
              <img
                src={PresentTurboMind}
                alt="pic"
                loading="lazy"
                className="pb-8 pt-4 lg:w-[850px] w-[500px] h-full object-cover"
              />
            </div>
            <div className="text-gray-700">
              <h4 className="text-lg font-bold mb-2 font-aeion">
                ERP-Inspired AI Integration:
              </h4>
            </div>
            <ul className="list-disc px-4 text-gray-700">
              <li>
                Drawing parallels with ERP systems, this platform serves as a
                central AI hub, integrating various AI tasks to streamline
                information flow within an organization.
              </li>
              <li>
                Diverse AI Functionality: Equipped with modules for various
                tasks, including policy queries, email content generation,
                advertising material creation, sentiment analysis, and
                comprehensive data processing and analysis.
              </li>
              <li>
                Transformative Business Tool: Envisioned as an AI-powered
                equivalent to ERP systems, this platform aims to be an integral
                part of daily operations, empowering businesses to efficiently
                harness AI.
              </li>
              <li>
                AI Revolution in Business Processes: Like ERP systems
                revolutionized business process management, this platform is set
                to redefine how AI is utilized in organizational settings.
              </li>
            </ul>
          </div>

          <p className="text-gray-700">
            The boxMind Enterprise system and the Unified AI Platform represent
            a significant advancement in the field of AI for business
            applications. By offering a comprehensive suite of tools and
            features, these solutions are poised to transform how enterprises
            engage with AI, enhancing operational efficiency, insight
            generation, and overall value creation. As a cornerstone of our
            service offerings, boxMind Enterprise is uniquely positioned to
            redefine the landscape of enterprise AI engagement, marking a new
            era in business intelligence and data-driven decision-making.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Philosophy;
